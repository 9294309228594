import { deleteRequest, getRequest, patchRequest, postRequest } from "./setup";

export function fetchExamTypes() {
  let url = "/admin/exam-types/all";
  return getRequest(url);
}

export function fetchSubjects() {
  let url = "/admin/subjects/all";
  return getRequest(url);
}

export function postSubject(body) {
  return postRequest(`/admin/subjects`, body);
}

export function fetchInstructions(options) {
  let url = `/admin/instructions/all`;
  if (options && options.query) {
    let queryString = `?`;
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== null && value !== undefined)
        queryString += `&${key}=${value}`;
    });
    url += queryString;
  }
  return getRequest(url);
}

export function fetchQuestionTypes() {
  let url = `/admin/question-types/all`;
  return getRequest(url);
}

export function postInstruction(body) {
  return postRequest(`/admin/instructions`, body);
}

export function patchInstruction(body) {
  return patchRequest(`/admin/instructions`, body);
}

export function inactiveInstruction(body) {
  return postRequest(`/admin/instructions/inactive`, body);
}

export function fetchInstitutes() {
  return getRequest(`/admin/institutes/all`);
}

export function fetchCategories() {
  return getRequest(`/admin/test-categories/all`);
}

export function fetchBranches() {
  return getRequest(`/admin/branches/all`);
}

export function fetchAssessmentTypes() {
  return getRequest(`/admin/assessment_types/all`);
}

export function postLessonIds(body) {
  return postRequest(`/app/lessons`, body);
}

export function deleteLessons(lessonIds) {
  return deleteRequest(`/admin/tests/lessons/${lessonIds}`);
}


export function downloadAttemptSheet() {
  return getRequest(`/admin/tests/attempts/all?isDownload=true`, 'blob')
}