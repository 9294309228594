import React, { useEffect, useState } from "react";
import style from "./Navbar.module.scss";
import { CgProfile } from "react-icons/cg";

import WithPadding from "components-shared/WithPadding";
import useAppStore from "stores/AppStore";
import { ReactComponent as Logo } from "../../assets/logo.svg";

export default function Navbar() {
  const { userProfile } = useAppStore();

  const [loading, setLoading] = useState(true);

  function moveToHome() {
    window.location.href = "/";
  }

  function handleLogout() {
    localStorage.removeItem("authToken");
    window.location.href = "/auth/login";
  }

  useEffect(() => {
    if (Object.keys(userProfile).length !== 0) {
      setLoading(false);
    }
  }, [userProfile]);

  useEffect(() => {
    if (Object.keys(userProfile).length === 0) {
      setLoading(true);
    }
  }, []);

  if (loading) {
    return (
      <WithPadding className={style.wrapper}>
        <div onClick={moveToHome} className={style.logo}>
          <Logo className={style.logoImage} />
        </div>
      </WithPadding>
    )
  }

  return (
    <WithPadding className={style.wrapper}>
      <div onClick={moveToHome} className={style.logo}>
        <Logo className={style.logoImage} />
      </div>
      {
        Object.keys(userProfile).length !== 0 ?
          <div className={style.profileContainer}>
            <CgProfile />
            <div>
              <span>{userProfile.username}</span>
              <span onClick={handleLogout}>Logout</span>
            </div>
          </div>
          : null
      }
    </WithPadding>
  );
}
