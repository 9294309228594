import React, { useEffect, useState } from "react";
import style from "./AssessmentTypeSelector.module.scss";

import ReactSelect from "react-select";
import { fetchAssessmentTypes } from "apis/misc";

function AssessmentTypeSelector({ onChange, defaultValue }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  function getAssessmentTypes() {
    fetchAssessmentTypes()
      .then((resp) => {
        const formattedOpts = resp.data.data.map((item) => ({
          label: item.name,
          value: item,
        }));
        setOptions(formattedOpts);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(getAssessmentTypes, []);
  useEffect(() => {
    if (!options || !defaultValue) return;
    const defaultOption = options.find(
      (option) => option.value.uid === defaultValue 
    );
    setSelectedOption(defaultOption);
  }, [defaultValue, options]);

  return (
    <div className={style.wrapper}>
      <ReactSelect
        value={selectedOption}
        onChange={onChange}
        options={options}
      />
    </div>
  );
}

export default AssessmentTypeSelector;