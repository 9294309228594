import React, { useEffect, useState } from "react";

import ReactSelect from "components-shared/ReactSelect";
import { fetchSubjects } from "apis/misc";

export default function SubjectSelector({ onChange, defaultValue, disabled, width }) {
  const [options, setOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);

  function getSubjects() {
    fetchSubjects().then((resp) => {
      const optArr = resp.data.data.map((item) => ({
        label: item.subject,
        value: item,
      }));

      setOptions(optArr);
    });
  }

  useEffect(getSubjects, []);
  useEffect(() => {
    if (!options || !defaultValue) return;
    const defaultOption = options.find(
      (option) => option.value.uid === defaultValue
    );
    setSelectedOption(defaultOption);
  }, [defaultValue, options]);

  return (
    <ReactSelect
      options={options}
      isDisabled={disabled}
      onChange={onChange}
      value={selectedOption}
      width={width}
    />
  );
}
