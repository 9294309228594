import {
  deleteRequest,
  getRequest,
  patchRequest,
  postRequest,
  putRequest,
} from "./setup";

export function postTest(body) {
  return postRequest("/admin/tests", body);
}

export function fetchTests(options) {
  let url = "/admin/tests/all";
  if (options && options.query) {
    let queryString = `?`;
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== null && value !== undefined)
        queryString += `&${key}=${value}`;
    });
    url += queryString;
  }

  return getRequest(url);
}

export function fetchTest(testId, options) {
  let url = `/admin/tests/${testId}`;
  if (options && options.query) {
    let queryString = `?`;
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== null && value !== undefined)
        queryString += `&${key}=${value}`;
    });
    url += queryString;
  }

  return getRequest(url);
}

export function fetchQuestions(testId, options) {
  let url = `/admin/tests/questions/${testId}`;
  if (options && options.query) {
    let queryString = `?`;
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== null && value !== undefined)
        queryString += `&${key}=${value}`;
    });
    url += queryString;
  }

  return getRequest(url);
}

export function postTestSection(body) {
  return postRequest("/admin/tests/sections/", body);
}

export function fetchSections(testId, options) {
  let url = `/admin/tests/sections/${testId}`;
  if (options && options.query) {
    let queryString = `?`;
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== null && value !== undefined)
        queryString += `&${key}=${value}`;
    });
    url += queryString;
  }

  return getRequest(url);
}

export function postTestQuestions(body) {
  return postRequest("/admin/tests/questions", body);
}

export function deleteTestQuestions(body) {
  return deleteRequest("/admin/tests/questions", body);
}

export function fetchTestInstructions(testId) {
  return getRequest(`/admin/tests/instructions/${testId}`);
}

export function fetchTestLessons(testId) {
  return getRequest(`/admin/tests/lessons/${testId}`);
}

export function patchTestInstructions(body) {
  return patchRequest(`/admin/tests/instructions`, body);
}

export function putTest(body) {
  return putRequest(`/admin/tests`, body);
}

export function deleteTestSection(body) {
  return deleteRequest(`/admin/tests/sections`, body);
}

export function editTestSection(body) {
  return putRequest(`/admin/tests/sections`, body);
}

export function patchTestQuestion(body) {
  return patchRequest(`/admin/tests/questions`, body);
}

export function deleteTestQuestion(body) {
  return deleteRequest(`/admin/tests/questions`, body);
}

export function fetchTestCandidates(testId, options) {
  let url = `/admin/tests/candidates/${testId}}`;
  if (options && options.query) {
    let queryString = `?`;
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== null && value !== undefined)
        queryString += `&${key}=${value}`;
    });
    url += queryString;
  }

  return getRequest(url);
}

export function postTestAttempts(body) {
  return postRequest(`/admin/tests/attempts`, body);
}

export function publishTest(body) {
  return postRequest(`/admin/tests/publish`, body);
}

export function patchTestStatus(body) {
  return patchRequest(`/admin/tests/update`, body);
}

export function fetchTestAttempts(options) {
  let url = `/admin/tests/attempts/all?page_size=20`;

  if (options && options.query) {
    let queryString = ``;
    Object.entries(options.query).forEach(([key, value]) => {
      if (value !== null && value !== undefined)
        queryString += `&${key}=${value}`;
    });

    url += queryString;
  }

  return getRequest(url);
}

export function archiveTest(body) {
  return postRequest(`/admin/tests/archive`, body);
}



export function updateTestAttemptScore(body) {
  return patchRequest(`/admin/tests/attempts/score`, body);
}