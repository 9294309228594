import axios from "axios";

import config from "config/config.js";
const BASE_URL = config.apiBaseUrl;

axios.interceptors.response.use(
  (response) => {
    return Promise.resolve(response);
  },
  (error) => {
    if (error.response && error.response.status === 403) {
      localStorage.clear();
      return (window.location.href = "/auth/login");
    }
    return Promise.reject(error);
  }
);

function getAuthToken() {
  return localStorage.getItem("authToken");
}

export const getRequest = (path, responseType = "json") => {
  return axios.get(`${BASE_URL}${path}`, {
    responseType,
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
};

export const postRequest = (path, data) => {
  return axios.post(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
};

export const patchRequest = (path, data) => {
  return axios.patch(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
};

export const multiGetRequest = async (paths) => {
  let arr = [];

  paths.forEach((item) => {
    arr.push(
      axios.get(`${BASE_URL}${item}`, {
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
      })
    );
  });
  return axios.all(arr);
};

export const multiPostRequest = async (configs) => {
  let arr = [];
  const authToken = getAuthToken();
  configs.forEach((item) => {
    arr.push(
      axios.post(`${BASE_URL}${item.url}`, item.data, {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      })
    );
  });
  return axios.all(arr);
};

export const putRequest = (path, data) => {
  return axios.put(`${BASE_URL}${path}`, data, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
};

export const headRequest = (path) => {
  return axios.head(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const deleteRequest = (path, body) => {
  return axios.delete(`${BASE_URL}${path}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    data: body,
  });
};
