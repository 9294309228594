import create from "zustand";

const useStore = create((set) => ({
  examType: {},
  setExamType: (payload) => set((state) => ({ examType: payload })),

  subject: {},
  setSubject: (payload) => set((state) => ({ subject: payload })),

  institute: {},
  setInstitute: (payload) => set((state) => ({ institute: payload })),

  test_category: {},
  setTestCategory: (payload) => set((state) => ({ test_category: payload })),

  branch: {},
  setBranch: (payload) => set((state) => ({ branch: payload })),

  assessment_type: {},
  setAssessment_type: (payload) => set((state) => ({ assessment_type: payload })),

  name: "",
  setName: (payload) => set((state) => ({ name: payload })),

  nameAlias: "",
  setNameAlias: (payload) => set((state) => ({ nameAlias: payload })),

  scheme: "",
  setScheme: (payload) => set((state) => ({ scheme: payload })),

  timeAlloted: 0,
  setTimeAlloted: (payload) => set((state) => ({ timeAlloted: payload })),

  generalInstruction: {},
  setGeneralInstruction: (payload) =>
    set((state) => ({ general_instruction: payload })),

  specificInstruction: {},
  setSpecificInstruction: (payload) =>
    set((state) => ({ specificInstruction: payload })),

  isLoading: false,
  setIsLoading: (payload) => set(() => ({ isLoading: payload })),
}));

export default useStore;
