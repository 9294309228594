import useStore from "./TestAdd.store";
import { useNavigate } from "react-router-dom";
import { postTest } from "apis/tests";
import { toast } from "react-toastify";

export function useTestAdd() {
  const navigate = useNavigate();

  const {
    examType,
    setExamType,

    subject,
    setSubject,

    institute,
    setInstitute,

    test_category,
    setTestCategory,

    branch,
    setBranch,

    assessment_type,
    setAssessment_type,

    name,
    setName,

    nameAlias,
    setNameAlias,

    scheme,
    setScheme,

    timeAlloted,
    setTimeAlloted,

    setIsLoading,

    generalInstructionId,
    setGeneralInstruction,

    specificInstructionId,
    setSpecificInstruction,
  } = useStore((state) => ({
    examType: state.examType,
    setExamType: state.setExamType,

    subject: state.subject,
    setSubject: state.setSubject,

    institute: state.institute,
    setInstitute: state.setInstitute,

    test_category: state.test_category,
    setTestCategory: state.setTestCategory,

    branch: state.branch,
    setBranch: state.setBranch,

    assessment_type: state.assessment_type,
    setAssessment_type: state.setAssessment_type,

    name: state.name,
    setName: state.setName,

    nameAlias: state.nameAlias,
    setNameAlias: state.setNameAlias,

    scheme: state.scheme,
    setScheme: state.setScheme,

    timeAlloted: state.timeAlloted,
    setTimeAlloted: state.setTimeAlloted,

    generalInstructionId: state.generalInstructionId,
    setGeneralInstruction: state.setGeneralInstruction,

    specificInstructionId: state.specificInstructionId,
    setSpecificInstruction: state.setSpecificInstruction,

    setIsLoading: state.setIsLoading,
  }));

  function handleChangeExamType(e) {
    setExamType(e);
  }

  function handleChangeSubject(e) {
    setSubject(e.value.uid);
  }

  function handleChangeInstitute(e) {
    setInstitute(e.value.uid);
  }

  function handleChangeTestCategory(e) {
    setTestCategory(e.value.uid);
  }

  function handleChangeBranch(e) {
    setBranch(e.value.uid);
  }
  
  function handleChangeAssessment_type(e) {
    setAssessment_type(e.value.uid);
  }

  function handleChangeName(e) {
    const { value } = e.target;
    setName(value);
  }

  function handleChangeNameAlias(e) {
    const { value } = e.target;
    setNameAlias(value);
  }

  function handleChangeAllotedTime(e) {
    const { value } = e.target;
    setTimeAlloted(value);
  }

  function handleChangeScheme(e) {
    const { value } = e.target;
    setScheme(value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    const postBody = {
      institute_id: institute,
      test_category_id: test_category,
      branch_id: branch,
      subject_id: subject,
      name,
      scheme,
      name_alias: nameAlias,
      assessment_type_id: assessment_type,
      time_alloted: parseInt(timeAlloted),
    };

    setIsLoading(true);

    postTest(postBody)
      .then((resp) => {
        navigate("/tests");
        toast.success('Test added');
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        toast.error('Failed to add test');
        setIsLoading(false);
      });
  }

  return {
    handleChangeExamType,
    handleChangeSubject,
    handleChangeName,
    handleChangeScheme,
    handleChangeAllotedTime,
    handleSubmit,
    handleChangeInstitute,
    handleChangeTestCategory,
    handleChangeBranch,
    handleChangeNameAlias,
    handleChangeAssessment_type,
  };
}
