import InstituteSelector from "components-shared/InstituteSelector";
import CategorySelector from "components-shared/CategorySelector";
import BranchSelector from "components-shared/BranchSelector";
import SubjectSelector from "components-shared/SubjectSelector";
import WithPadding from "components-shared/WithPadding";
import Preloader from "components-shared/Preloader";

import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";

import React from "react";
import style from "./TestAdd.module.scss";

import { useTestAdd } from "./useTestAdd";
import useStore from "./TestAdd.store";
import AssessmentTypeSelector from "components-shared/AssessmentTypeSelector";

export default function TestAdd() {
  const {
    handleChangeSubject,
    handleChangeName,
    handleChangeScheme,
    handleChangeAllotedTime,
    handleSubmit,
    handleChangeInstitute,
    handleChangeTestCategory,
    handleChangeBranch,
    handleChangeNameAlias,
    handleChangeAssessment_type,
  } = useTestAdd();

  const { subject, institute, test_category, branch, assessment_type } = useStore((state) => ({
    subject: state.subject,
    institute: state.institute,
    test_category: state.test_category,
    branch: state.branch,
    assessment_type: state.assessment_type,
  }));

  const { isLoading } = useStore((state) => ({
    isLoading: state.isLoading,
  }));

  return (
    <WithPadding className={style.wrapper}>
      {isLoading ? (
        <Preloader />
      ) : (
        <>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/tests">
              Tests
            </Link>

            <Typography color="text.primary">Add Test</Typography>
          </Breadcrumbs>
          <h4>Add Test</h4>
          <form>
            <div className={style.row}>
              <label>Select Institute</label>
              <InstituteSelector
                defaultValue={institute}
                onChange={handleChangeInstitute}
              />
            </div>

            <div className={style.row}>
              <label>Select Test Category</label>
              <CategorySelector
                defaultValue={test_category}
                onChange={handleChangeTestCategory}
              />
            </div>

            <div className={style.row}>
              <label>Select Branch</label>
              <BranchSelector
                defaultValue={branch}
                onChange={handleChangeBranch}
              />
            </div>

            <div className={style.row}>
              <label>Select Assessment Type</label>
              <AssessmentTypeSelector
                defaultValue={assessment_type}
                onChange={handleChangeAssessment_type}
              />
            </div>

            <div className={style.row}>
              <label>Select Subject</label>
              <SubjectSelector
                defaultValue={subject}
                onChange={handleChangeSubject}
              />
            </div>

            <div className={style.row}>
              <label>Test Name</label>
              <input type="text" name="name" onChange={handleChangeName} />
            </div>


            <div className={style.row}>
              <label>Test Alias Name</label>
              <input type="text" name="nameAlias" onChange={handleChangeNameAlias} />
            </div>

            <div className={style.row}>
              <label>Scheme</label>
              <input type="text" name="scheme" onChange={handleChangeScheme} />
            </div>

            <div className={style.row}>
              <label>Time Alloted (seconds)</label>
              <input
                type="number"
                name="time_alloted"
                onChange={handleChangeAllotedTime}
              />
            </div>

            <button onClick={handleSubmit}>Create Test</button>
          </form>
        </>
      )}
    </WithPadding>
  );
}
